'use strict';

var siteInit = {

	DOMready: function() {

		if ($('.js-navicon').length > 0) {
            siteInit.mobileNavigation();
        }

        if ( $('.js-slider--primary').length > 0 ) {
			siteInit.slider();
		}

		if ( $('.js-button-map').length > 0 ) {
			siteInit.map();
		}

		if ( $('[href$=".jpg"], [href$=".png"], [href$=".gif"]').length > 0 ) {
			siteInit.modal();
		}

		if ( $('.js-form').length > 0 ) {
			siteInit.formAjax();
		}

		siteInit.cookie();

		if ( $('.popup').length > 0 ) {
				siteInit.closePopUp();
			}

	},

	DOMload: function() {

	},

	windowScroll: function() {

	},

	windowResize: function() {

	},

	/* Popup */
	closePopUp: function() {

		$('.popup__close').click(function(){
			$('.popup').fadeOut(1000);
		});
	},

	// Mobile navigation
    mobileNavigation: function() {

        $('body').on('click', '.js-navicon', function(event) {
        	console.log('prova');
            event.preventDefault();

            $(this).toggleClass('is-active');
            $('.navigation--primary').toggleClass('is-active');

        });

    },

	// Slider
	slider: function() {

		$(".js-slider--primary").owlCarousel({
			items: 1,
			loop:false,
			nav:true,
			dots:false,
			//autoHeight: true,
			onChanged : callback,
			onInitialized : callback
		});

		function callback(event) {

		    var items     = event.item.count;     // Number of items
		    var item      = event.item.index;     // Position of the current item

		    $(".pagination__item").html(item + 1);
		    $(".pagination__total-items").html(items);
		}

	},

	// Map
	map: function() {

		var buttonMap = $('.js-button-map');
		var map = $('.map');
		var originalTextButton = buttonMap.text();
		buttonMap.on('click', function() {

			if ( buttonMap.hasClass( "is-active" ) ) {
  				buttonMap.removeClass( "is-active").text(originalTextButton);
    			map.fadeOut();
			} else {
				buttonMap.addClass( "is-active").text('Chiedi tramite form');
				map.fadeIn();
			}

		});

	},


	// Modal
	modal: function() {

		$('[href$=".jpg"], [href$=".png"], [href$=".gif"]').colorbox({
			transition: 'elastic',
			speed: 400,
			opacity: 0.8,
			slideshow: true,
			slideshowSpeed: 4000,
			itemsnitialWidth: 50,
			initialHeight: 50,
			maxWidth: '90%',
			maxHeight: '90%',
		});

	},

	// Form Ajax
	formAjax: function() {

		$( '.form' ).on( 'submit', function( event ) {

			event.preventDefault();

			$('.form__row').removeClass('form__row--error');
			$('.has-error, .has-success').remove();

			$.ajax({

				type: $( this ).attr('method'),
				url: $( this ).attr('action'),
				data: $( this ).serialize(),
				dataType: 'json'

			}).done( function( data ) {

				// console.log(data);  // Only for debug

				if ( !data.success ) {
						//console.log(data);
					$.each( data.errors, function( element, message ) {

							//console.log(element);
						$('#' + element).parent('.form__row').addClass('form__row--error').append('<div class="message message--fill has-error">' + message + '</div>');
					});

				} else {
						//console.log('ko');
						window.location.replace("https://www.sinafilati.com/grazie-per-averci-contattato.html");
					//$( '.form' ).append('<div class="message message--fill has-success">' + data.message + '</div>');
				}

			}).fail( function() {


				// console.log(data);  // Only for debug

			});

		});

	},

	// Cookie Banner
	cookie: function() {
		$.cookieBanner({
			cookiePageUrl: 'privacy-e-cookie.html'
		});
	}

};


$( document ).ready( function() {
	siteInit.DOMready();
});

$( window ).load( function() {
	siteInit.DOMload();
});

$( window ).scroll( function () {
	siteInit.windowScroll();
});

$( window ).resize( function() {
	siteInit.windowResize();
});
